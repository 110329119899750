<template>
  <div class="login">
    <div class="header text-center">
      <div class="title font-weight-black">{{ appName }}</div>

      <div class="subtitle light-blue--text text--lighten-3 font-weight-bold">
        Business Intelligence
      </div>
    </div>

    <div class="content">
      <v-card class="login-card" flat>
        <v-card-title class="justify-center"> User Login </v-card-title>

        <v-spacer />

        <v-card-text class="pb-0">
          <v-alert
            v-text="loginErrorText"
            :value="loginError"
            dense
            prominent
            type="error"
          ></v-alert>

          <v-form ref="form">
            <v-text-field
              v-model="username"
              :error-messages="usernameError"
              :readonly="loading"
              :rules="[rules.required]"
              label="Username"
              outlined
            />

            <v-text-field
              v-model="password"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :error-messages="passwordError"
              :readonly="loading"
              :rules="[rules.required]"
              :type="showPassword ? 'text' : 'password'"
              label="Password"
              outlined
              @click:append="showPassword = !showPassword"
              @keyup.enter="submit"
            />
          </v-form>
        </v-card-text>

        <v-spacer />

        <v-card-actions class="justify-center pt-0">
          <v-btn color="light-blue" large @click="submit">
            <span v-if="!loading">Login</span>

            <v-progress-circular
              :size="20"
              indeterminate
              v-else
            ></v-progress-circular>
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>

    <div class="footer">
      <v-img
        class="logo"
        lazy-src="/img/logo.png"
        max-width="150"
        src="/img/logo.png"
      ></v-img>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Login',

  data: () => ({
    username: '',
    password: '',
    usernameError: '',
    passwordError: '',
    showPassword: false,
    rules: {
      required: (value) => !!value || 'Required.',
    },
    loading: false,
    loginError: false,
    loginErrorText: 'Username atau password salah',
  }),

  computed: {
    appName() {
      return process.env.VUE_APP_NAME
    },
  },

  methods: {
    submit() {
      this.loginError = false

      if (!this.loading) {
        let valid = true

        if (!this.username.length || !this.password.length) {
          this.$refs.form.validate()
          valid = false
        }

        if (!valid) return

        this.loading = true

        const user = new FormData()

        user.append('username', this.username)
        user.append('password', this.password)

        this.$http
          .post('/login', user)
          .then((result) => {
            const user = result.data

            this.$cookies.set('session', {
              id: user.id,
              username: user.user_id,
              name: user.real_name,
              isSalesman: user.is_salesman == 1 ? 1 : 0,
            })

            this.$refs.form.reset()
            this.loading = false

            if (user.is_salesman == 1) {
              this.$router.push('/inventory/items')
            } else {
              this.$router.push('/')
            }
          })
          .catch((error) => {
            console.log(error.response)
            this.$refs.form.reset()
            this.loginError = true
            this.loginErrorText = error.response.data.message
              ? error.response.data.message
              : 'Tidak dapat mengakses server'
            this.loading = false
          })
      }
    },
  },
}
</script>

<style lang="scss">
.login {
  display: flex;
  flex-flow: column;

  .header {
    padding-top: 20px;
    padding-bottom: 20px;

    .title {
      font-size: 1.5rem !important;
    }
  }

  .content {
    display: flex;
    flex: 1;
    flex-flow: column;

    .login-card {
      margin-top: auto;
      margin-bottom: auto;
      padding-left: 20px;
      padding-right: 20px;

      background-color: transparent;
      box-shadow: none;
    }
  }

  .footer {
    padding: 20px;

    .logo {
      margin-left: auto;
      margin-right: auto;
    }
  }
}
</style>
